import { ref } from "vue";

export default function Table() {
  const filter = ref({
    page: 1,
    query: null
  });

  function handleQueryFilter(query: string) {
    this.filter.query = query;
    this.filter.page = 1;
    this.store._index(this.filter);
  }

  function handleCleanFilter(): void {
    this.filter.query = null;
    this.filter.page = 1;
    this.store._index(this.filter);
  }

  function handlePaginate(page: string | number): void {
    this.filter.page = page;
    this.store._index(this.filter);
  }

  return {
    filter,
    handleQueryFilter,
    handleCleanFilter,
    handlePaginate
  };
}
