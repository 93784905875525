import { IHttpService, HttpService } from "@/shared/http/Index";
import {
  IWordGroupRepository,
  IWordGroupResponse,
  IWordGroupIndexResponse,
  IWordGroupData,
  IGetWordsByWordGroupResponse,
} from "../domain/WordGroup.types";
import { WordGroupSyncParser } from "../domain/WordGroupSyncParser";

export class WordGroupService implements IWordGroupRepository {
  private httpService: IHttpService;
  private parser: WordGroupSyncParser;

  constructor() {
    this.httpService = new HttpService();
    this.parser = new WordGroupSyncParser();
    this.httpService.initService("educational/word-group");
  }

  async index(params: any): Promise<IWordGroupIndexResponse> {
    return await this.httpService.get({ url: "/" }, params);
  }

  async show(id: number): Promise<IWordGroupResponse> {
    return await this.httpService.get({ url: `/${id}` });
  }

  async store(data: IWordGroupData): Promise<IWordGroupResponse> {
    return await this.httpService.post({ url: "/" }, data);
  }

  async update(id: number, data: IWordGroupData): Promise<IWordGroupResponse> {
    return await this.httpService.put({ url: `/${id}` }, data);
  }

  async destroy(id: number): Promise<any> {
    return await this.httpService.delete({ url: `/${id}` });
  }

  async syncWords(data: object): Promise<any> {
    return await this.httpService.post({ url: "/sync-words" }, this.parser.fromDomain(data));
  }

  async getSyncWords(id: number): Promise<any> {
    return await this.httpService.get({ url: `/sync-words/${id}` });
  }

  async getWordsByWordGroup(id: number): Promise<IGetWordsByWordGroupResponse> {
    return await this.httpService.get({ url: `/word/${id}` });
  }

  async storeTrainedWord(data: object): Promise<any> {
    return await this.httpService.post({ url: `/trained-word/` }, data);
  }
}
