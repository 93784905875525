import { ISkillData, ISkillParser } from "../domain/Skill.types";
import { jsonToFormData } from "@/shared/helpers/General";
export class SkillParser implements ISkillParser {
  apply(response: any): Promise<any> {
    if (response.data instanceof Array) {
      response.data.map((item: ISkillData) => this.toDomain(item));
    } else if (response.data instanceof Object) {
      response.data = this.toDomain(response.data);
    }
    return response;
  }

  toDomain(data: ISkillData): ISkillData {
    const parserData: ISkillData = {
      id: data.id,
      name: data.name,
      description: data.description,
      cooldown: data.cooldown,
      type: data.type,
      target: data.target,
      range: data.range,
      attachment: data.attachment,
      effects: data.effects,
      character_class_id: data.character_class_id,
      level_to_learn: data.level_to_learn,
    };
    return parserData;
  }

  fromDomain(data: ISkillData): FormData {
    const parserData: ISkillData = {
      id: data.id,
      name: data.name,
      description: data.description,
      cooldown: data.cooldown,
      type: data.type,
      target: data.target,
      range: data.range,
      attachment: data.attachment,
      effects: data.effects,
      character_class_id: data.character_class_id,
      level_to_learn: data.level_to_learn,
    };
    const formData = jsonToFormData(parserData);
    if (parserData.id) formData.append("_method", "PUT");
    return formData;
  }
}
