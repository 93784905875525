export default {
  auth(to: any, from: any, next: any) {
    const token = localStorage.getItem("_token");
    if (!token || token === "undefined") {
      next("/");
    }
    next();
  },
  hasAuth(to: any, from: any, next: any) {
    const token = localStorage.getItem("_token");
    if (token && token !== "undefined") {
      // next("/note"); corrigir
    }
    next();
  },
};
