import { HttpService, IHttpService } from "@/shared/http/Index";
import { IWordData, IWordIndexResponse, IWordParser, IWordRepository, IWordResponse } from "../domain/Word.types";
import { WordParser } from "./WordParser";

export class WordService implements IWordRepository {
  private httpService: IHttpService;
  private parser: IWordParser;

  constructor() {
    this.httpService = new HttpService();
    this.parser = new WordParser();
    this.httpService.initService("educational/word");
  }

  async index(params: any): Promise<IWordIndexResponse> {
    return await this.httpService.get({ url: "/" }, params);
  }

  async show(id: number): Promise<IWordResponse> {
    return await this.httpService.get({ url: `/${id}` });
  }

  async store(data: IWordData): Promise<IWordResponse> {
    return await this.httpService.post({ url: "/" }, this.parser.fromDomain(data));
  }

  async update(id: number, data: IWordData): Promise<IWordResponse> {
    return await this.httpService.put({ url: `/${id}` }, this.parser.fromDomain(data));
  }

  async destroy(id: number): Promise<any> {
    return await this.httpService.delete({ url: `/${id}` });
  }

  async getToSyncForGroup(): Promise<any> {
    return await this.httpService.get({ url: "/sync-for-group" });
  }
}
