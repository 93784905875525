import { defineStore } from "pinia";
import { update, push, destroy } from "@/shared/store/BaseStore";
import { LoadingStore } from "@/shared/store/LoadingStore";
import Swal from "sweetalert2";
import { swalDeleted, swalError } from "@/shared/helpers/Swal";
import { IMonsterData } from "../domain/Monster.types";
import { Fetch, Show, Update, Store, Destroy } from "../useCase/Index";

export const MonsterStore = defineStore("monster", {
  state: () => ({
    items: [] as Array<IMonsterData>,
    item: {} as IMonsterData,
    battleMonsters: [] as Array<any>,
  }),
  actions: {
    async _index(params: object) {
      LoadingStore().start();
      return Fetch(params).then((response) => {
        this.items = response.data;
        LoadingStore().end();
      });
    },

    async _show(id: number) {
      LoadingStore().start();
      return Show(id).then((response) => {
        this.item = response.data;
        LoadingStore().end();
      });
    },

    async _store(data: IMonsterData) {
      LoadingStore().start();
      return Store(data)
        .then((response) => {
          document.dispatchEvent(new CustomEvent("closeModal"));
          push(this.items, response.data);
        })
        .catch((error) => {
          Swal.fire(swalError(error.response || error));
        })
        .finally(() => LoadingStore().end());
    },

    async _update(data: IMonsterData) {
      LoadingStore().start();
      return Update(data.id, data)
        .then((response) => {
          document.dispatchEvent(new CustomEvent("closeModal"));
          update(this, response.data);
        })
        .catch((error) => {
          Swal.fire(swalError(error.response || error));
        })
        .finally(() => LoadingStore().end());
    },

    async _destroy(id: number) {
      LoadingStore().start();
      return Destroy(id)
        .then(() => {
          Swal.fire(swalDeleted());
          destroy(this.items, id);
        })
        .catch((error) => {
          Swal.fire(swalError(error.response || error));
        })
        .finally(() => LoadingStore().end());
    },

    _itemReset() {
      this.item = {} as IMonsterData;
    },

    _storeByReference(data: any) {
      push(this.battleMonsters, data);
    },

    _updateByReference(data: any) {
      update(this, data, "battleMonsters");
    },
  },
});
