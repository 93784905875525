<script lang="js">

import { defineComponent,reactive } from "vue";
import { LoginStore } from "@/modules/general/login/store/LoginStore";

export default defineComponent({
  name: "DefaultLayout",
  components:{},
  methods: {
    openMenu() {
      document.body.classList.add('g-sidenav-pinned')
    },
  },
  setup() {
    const menu = reactive([
      {
        name : 'Operacional',
        active: false,
        admin: true,
        icon: 'hammer',
        childrens:[
          {
            name : 'Anotações',
            path :'/note',
            icon: 'clipboard-list',
          }
        ]
      },
      {
        admin: true,
        name : 'Educacional',
        active: false,
        icon: 'user-graduate',
        childrens:[
          {
            name : 'Palavras',
            path :'/word',
            icon: 'marker',
          }
        ]
      },
      {
        admin: true,
        name : 'Geral',
        active: false,
        icon: 'cog',
        childrens:[
          {
            name : 'Usuários',
            path :'/users',
            icon: 'users',
          }
        ]
      },
       {
        name : 'RPG',
        active: false,
        admin: false,
        icon: 'gamepad',
        childrens:[
          {
            name : 'Personagens',
            path :'/character',
            icon: 'user-ninja',
          },
          {
            name : 'Meu Personagem',
            path :'/current-character',
            icon: 'user',
          },
          {
            name : 'Classes',
            path :'/character-class',
            icon: 'scroll',
            admin: true,
          },
          {
            name : 'Inventário',
            path :'/character-item',
            icon: 'suitcase',
            admin: false,
          },
          {
            name : 'Monstros',
            path :'/monster',
            icon: 'hand-rock',
            admin: true,
          },
          {
            name : 'Mercado',
            path :'/sale',
            icon: 'shopping-cart',
            admin: false,
          },
          {
            name : 'Skills',
            path :'/skill',
            icon: 'dragon',
            admin: true,
          },
        ]
      },
    ])
    return { store: LoginStore(), menu };
  },
  mounted() {
    document.body.classList.add('g-sidenav-show',)
    document.body.classList.add('bg-gray-100')
    document.addEventListener("mouseup", e => {
      if(Array.from(document.body.classList).includes('g-sidenav-pinned') && Array.from(e.toElement.classList).includes('custom-dropdown') === false && Array.from(e.toElement.classList).includes('anchor') === false) {
        document.body.classList.remove('g-sidenav-pinned')
      }
    })
  }
});
</script>

<template>
  <div class="">
    <aside
      class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark"
      id="sidenav-main"
    >
      <div class="sidenav-header">
        <i
          class="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
          aria-hidden="true"
          id="iconSidenav"
        >
        </i>
        <a style="padding: 0.8rem 2rem !important" class="navbar-brand m-0 justify-content-center d-flex" href="#">
          <img
            style="height: 3rem !important; max-height: 3rem !important"
            src="/assets/logo.png"
            class="navbar-brand-img h-100"
            alt="main_logo"
          />
        </a>
      </div>
      <hr class="horizontal light mt-0 mb-2" />
      <div class="collapse navbar-collapse w-auto h-auto" style="overflow: hidden" id="sidenav-collapse-main">
        <ul class="navbar-nav">
          <template v-for="(item, index) in menu" :key="index">
            <li v-if="!item.admin || store.logged_user.id === 1" class="nav-item">
              <a
                @click.prevent="item.active = !item.active"
                class="nav-link text-white anchor"
                :class="item.active ? 'anchor_opened' : 'anchor_closed'"
                href="#"
              >
                <div class="col-auto p-0 text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <icon :icon="item.icon" />
                </div>
                <span class="nav-link-text ms-1">{{ item.name }}</span>
              </a>
            </li>
            <template v-for="(children, childrenIndex) in item.childrens" :key="childrenIndex">
              <li v-if="!children.admin || store.logged_user.id === 1" v-show="item.active" class="nav-item ps-3">
                <a @click.prevent="$router.push(children.path)" class="nav-link text-white" href="#">
                  <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <icon :icon="children.icon" />
                  </div>
                  <span class="nav-link-text ms-1">{{ children.name }}</span>
                </a>
              </li>
            </template>
          </template>
          <li class="nav-item p-3">
            <a @click="store._logout()" class="btn bg-gradient-primary mt-4 w-100" href="#" type="button">Sair</a>
          </li>
        </ul>
      </div>
    </aside>

    <main @click="closeMenu" class="main-content border-radius-lg">
      <!-- Navbar -->

      <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
        <div class="container-fluid py-1 px-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
              <li class="breadcrumb-item text-sm">Centralizou</li>
              <li class="breadcrumb-item text-sm active" aria-current="page">{{ this.$route.meta.title }}</li>
            </ol>
          </nav>

          <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4 justify-content-end" id="navbar">
            <ul class="navbar-nav justify-content-end">
              <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
                <a @click.stop="openMenu" href="javascript:;" class="nav-link text-body p-0" id="iconNavbarSidenav">
                  <div class="sidenav-toggler-inner">
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                  </div>
                </a>
              </li>
              <li class="nav-item px-3 d-flex align-items-center">
                <a href="javascript:;" class="nav-link text-body p-0">
                  <icon icon="cog" />
                </a>
              </li>
              <li class="nav-item dropdown pe-2 d-flex align-items-center">
                <a href="javascript:;" class="nav-link text-body p-0">
                  <icon icon="bell" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="container-fluid py-4">
        <slot />
      </div>
      <!-- End Navbar -->
    </main>
  </div>
</template>

<style scoped>
.anchor_opened::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 1em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.28em solid transparent;
  border-bottom: 0;
  border-left: 0.28em solid transparent;
}
.anchor_closed::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 1em;
  vertical-align: 0.255em;
  content: "";
  border-left: 0.3em solid;
  border-bottom: 0.28em solid transparent;
  border-right: 0;
  border-top: 0.28em solid transparent;
}
</style>
