import { ref } from "vue";

export default function Modal() {
  const active = ref(false);

  function handleModalCreate() {
    this.store._itemReset();
    this.active = true;
  }

  function handleModalEdit(id: number) {
    this.store._itemReset();
    this.store._show(id).then(() => {
      this.active = true;
    });
  }

  function closeModal() {
    this.active = false;
  }

  return {
    active,
    handleModalCreate,
    handleModalEdit,
    closeModal,
  };
}
