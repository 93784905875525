import { defineStore } from "pinia";
import { update, push, destroy } from "@/shared/store/BaseStore";
import { IMeta } from "@/shared/http/Index";
import { LoadingStore } from "@/shared/store/LoadingStore";
import Swal from "sweetalert2";
import { swalDeleted, swalError, getMessage } from "@/shared/helpers/Swal";
import { ICharacterData } from "../domain/Character.types";
import {
  Fetch,
  Show,
  Update,
  Store,
  Destroy,
  GetCurrentCharacter,
  ApplyDamage,
  SendGold,
  GetMySkill,
  GetAllToBattle,
  SetSpeed,
} from "../useCase/Index";
import { IHttpService, HttpService } from "@/shared/http/Index";

export const CharacterStore = defineStore("Character", {
  state: () => ({
    items: [] as Array<ICharacterData>,
    meta: {} as IMeta,
    item: {} as ICharacterData,
    skills: [] as Array<any>,
    characters_by_damage: [] as Array<any>,
  }),
  actions: {
    async _index(params: object) {
      LoadingStore().start();
      return Fetch(params).then((response) => {
        this.items = response.data;
        this.meta = response.meta;
        LoadingStore().end();
      });
    },

    async _show(id: number) {
      LoadingStore().start();
      return Show(id).then((response) => {
        this.item = response.data;
        LoadingStore().end();
      });
    },

    async _store(data: ICharacterData) {
      LoadingStore().start();
      return Store(data)
        .then((response) => {
          document.dispatchEvent(new CustomEvent("closeModal"));
          push(this.items, response.data, this.meta);
        })
        .catch((error) => {
          Swal.fire(swalError(error.response || error));
        })
        .finally(() => LoadingStore().end());
    },

    async _update(data: ICharacterData) {
      LoadingStore().start();
      return Update(data.id, data)
        .then((response) => {
          document.dispatchEvent(new CustomEvent("closeModal"));
          update(this, response.data);
        })
        .catch((error) => {
          Swal.fire(swalError(error.response || error));
        })
        .finally(() => LoadingStore().end());
    },

    async _destroy(id: number) {
      LoadingStore().start();
      return Destroy(id)
        .then(() => {
          Swal.fire(swalDeleted());
          destroy(this.items, id, this.meta);
        })
        .catch((error) => {
          Swal.fire(swalError(error));
        })
        .finally(() => LoadingStore().end());
    },

    _itemReset() {
      this.item = {} as ICharacterData;
    },

    async _getCurrentCharacter() {
      LoadingStore().start();
      return GetCurrentCharacter().then((response) => {
        this.item = response.data;
        LoadingStore().end();
      });
    },

    async _applyDamage(id: number, damage: number) {
      LoadingStore().start();
      return ApplyDamage(id, damage).then((response) => {
        document.dispatchEvent(new CustomEvent("closeModal"));
        Swal.fire({
          title: "Solicitação Realizada",
          icon: "success",
          html: getMessage(response),
        });
        LoadingStore().end();
        this._index({});
      })
      .catch((error) => {
        Swal.fire(swalError(error.response || error));
        LoadingStore().end();
      });
    },

    async _sendGold(data: any) {
      LoadingStore().start();
      return SendGold(data)
        .then((response) => {
          document.dispatchEvent(new CustomEvent("closeModal"));
          Swal.fire({
            title: "Solicitação Realizada",
            icon: "success",
            html: getMessage(response),
          });
          this._index({});
        })
        .catch((error) => {
          Swal.fire(swalError(error));
        })
        .finally(() => LoadingStore().end());
    },

    async _getMySkill() {
      LoadingStore().start();
      return GetMySkill().then((response) => {
        this.skills = response.data;
        LoadingStore().end();
      });
    },

    async _getAllToBattle() {
      LoadingStore().start();
      return GetAllToBattle().then((response) => {
        this.items = response.data;
        LoadingStore().end();
      });
    },

    _updateByReference(data: any) {
      update(this, data);
    },

    async _setSpeed(id: number, speed: number) {
      return SetSpeed(id, speed).then((response) => {
        update(this, response.data);
      });
    },

    _getAllByDamage() {
      LoadingStore().start();
      const httpService: IHttpService = new HttpService();
      httpService.initService("rpg/character/by-damage");
      return httpService
        .get({ url: `` })
        .then((response) => {
          this.characters_by_damage = response.data;
        })
        .finally(() => LoadingStore().end());
    },
  },
});
