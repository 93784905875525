export class WordGroupSyncParser {
  fromDomain(data: any) {
    const parserData: any = {
      word_group_id: data.word_group_id,
      words_ids: data.words_ids,
    };
    parserData.words_ids = parserData.words_ids.reduce((a, o) => (a.push(o.id), a), []);
    return parserData;
  }
}
