import { defineStore } from "pinia";
import { push, destroy } from "@/shared/store/BaseStore";
import { IMeta } from "@/shared/http/Index";
import { LoadingStore } from "@/shared/store/LoadingStore";
import Swal from "sweetalert2";
import { swalDeleted, swalError, getMessage } from "@/shared/helpers/Swal";
import { ICharacterItemData } from "../domain/CharacterItem.types";
import { Fetch, Store, Destroy, Use } from "../useCase/Index";

export const CharacterItemStore = defineStore("CharacterItem", {
  state: () => ({
    items: [] as Array<ICharacterItemData>,
    meta: {} as IMeta,
    item: {} as ICharacterItemData,
  }),
  actions: {
    async _index(params: object) {
      LoadingStore().start();
      return Fetch(params).then((response) => {
        this.items = response.data;
        this.meta = response.meta;
        LoadingStore().end();
      });
    },

    async _store(data: ICharacterItemData) {
      LoadingStore().start();
      return Store(data)
        .then((response) => {
          document.dispatchEvent(new CustomEvent("closeModal"));
          push(this.items, response.data, this.meta);
        })
        .catch((error) => {
          Swal.fire(swalError(error.response || error));
        })
        .finally(() => LoadingStore().end());
    },

    async _destroy(id: number) {
      LoadingStore().start();
      return Destroy(id)
        .then(() => {
          Swal.fire(swalDeleted());
          destroy(this.items, id, this.meta);
        })
        .catch((error) => {
          Swal.fire(swalError(error));
        })
        .finally(() => LoadingStore().end());
    },

    _itemReset() {
      this.item = {} as ICharacterItemData;
    },

    async _use(id: number) {
      LoadingStore().start();
      return Use(id)
        .then((response) => {
          Swal.fire({
            title: "Solicitação Realizada",
            icon: "success",
            html: getMessage(response),
          });
        })
        .catch((error) => {
          Swal.fire(swalError(error.response || error));
        })
        .finally(() => {
          this._index({});
          LoadingStore().end();
        });
    },
  },
});
