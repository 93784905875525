import { createStore } from "vuex";
import dice from "@/modules/rpg/dice/store/dice/dice.store";
import character from "@/modules/rpg/character/store/character.store";

const store = createStore({
  modules: {
    dice,
    character,
  },
});

export default store;
