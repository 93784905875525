import { IHttpService, HttpService } from "@/shared/http/Index";
import {
  IUserRepository,
  IUserResponse,
  IUserIndexResponse,
  IUserData,
} from "../domain/User.types";

export class UserService implements IUserRepository {
  private httpService: IHttpService;

  constructor() {
    this.httpService = new HttpService();
    this.httpService.initService("general/user");
  }

  async index(params: any): Promise<IUserIndexResponse> {
    return await this.httpService.get({ url: "/" }, params);
  }

  async show(id: number): Promise<IUserResponse> {
    return await this.httpService.get({ url: `/${id}` });
  }

  async store(data: IUserData): Promise<IUserResponse> {
    return await this.httpService.post({ url: "/" }, data);
  }

  async update(id: number, data: IUserData): Promise<IUserResponse> {
    return await this.httpService.put({ url: `/${id}` }, data);
  }

  async destroy(id: number): Promise<any> {
    return await this.httpService.delete({ url: `/${id}` });
  }
}
