import { IHttpService, HttpService } from "@/shared/http/Index";
import {
  ICharacterClassRepository,
  ICharacterClassResponse,
  ICharacterClassIndexResponse,
  ICharacterClassData,
} from "../domain/CharacterClass.types";

export class CharacterClassService implements ICharacterClassRepository {
  private httpService: IHttpService;

  constructor() {
    this.httpService = new HttpService();
    this.httpService.initService("rpg/character-class");
  }

  async index(params: any): Promise<ICharacterClassIndexResponse> {
    return await this.httpService.get({ url: "/" }, params);
  }

  async show(id: number): Promise<ICharacterClassResponse> {
    return await this.httpService.get({ url: `/${id}` });
  }

  async store(data: ICharacterClassData): Promise<ICharacterClassResponse> {
    return await this.httpService.post({ url: "/" }, data);
  }

  async update(id: number, data: ICharacterClassData): Promise<ICharacterClassResponse> {
    return await this.httpService.put({ url: `/${id}` }, data);
  }

  async destroy(id: number): Promise<any> {
    return await this.httpService.delete({ url: `/${id}` });
  }
}
