import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import { router } from "./routes";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSweetalert2 from "vue-sweetalert2";
import vSelect from "vue-select";
import { defineRule } from "vee-validate";
import { required, email, min } from "@vee-validate/rules";
import store from "./store";

// css & icons
import "bootstrap/dist/css/bootstrap.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "vue-select/dist/vue-select.css";
import {
  faUserSecret,
  faHeart,
  faMarker,
  faCog,
  faBell,
  faPlus,
  faEdit,
  faTrash,
  faInfoCircle,
  faTimes,
  faArrowRight,
  faArrowLeft,
  faUndo,
  faBook,
  faClipboardList,
  faSave,
  faBan,
  faUserGraduate,
  faHammer,
  faClone,
  faFile,
  faUsers,
  faUserNinja,
  faGamepad,
  faScroll,
  faMedal,
  faCoins,
  faFire,
  faLevelUpAlt,
  faSync,
  faUser,
  faSuitcase,
  faHandRock,
  faInbox,
  faShoppingCart,
  faDragon,
  faGift,
  faChevronCircleDown,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import "./assets/css/material-dashboard.css?v=3.0.4";
import "sweetalert2/dist/sweetalert2.min.css";

library.add(
  faUserSecret,
  faHeart,
  faMarker,
  faCog,
  faBell,
  faPlus,
  faEdit,
  faTrash,
  faInfoCircle,
  faTimes,
  faArrowLeft,
  faArrowRight,
  faUndo,
  faBook,
  faClipboardList,
  faSave,
  faBan,
  faUserGraduate,
  faHammer,
  faClone,
  faFile,
  faUsers,
  faUserNinja,
  faGamepad,
  faScroll,
  faMedal,
  faCoins,
  faFire,
  faLevelUpAlt,
  faSync,
  faUser,
  faSuitcase,
  faFire,
  faHandRock,
  faInbox,
  faShoppingCart,
  faDragon,
  faGift,
  faChevronCircleDown,
  faEyeSlash,
);

defineRule("required", required);
defineRule("email", email);
defineRule("min", min);

createApp(App)
  .use(createPinia())
  .use(router)
  .use(VueSweetalert2)
  .use(VueAxios, axios)
  .use(store)
  .component("icon", FontAwesomeIcon)
  .component("select2", vSelect)
  .mount("#app");
