import { defineStore } from "pinia";
import { destroy } from "@/shared/store/BaseStore";
import { LoadingStore } from "@/shared/store/LoadingStore";
import Swal from "sweetalert2";
import { swalDeleted, swalError, getMessage } from "@/shared/helpers/Swal";
import { ISaleData } from "../domain/Sale.types";
import { Fetch, Store, Destroy, Buy } from "../useCase/Index";

export const SaleStore = defineStore("sale", {
  state: () => ({
    items: [] as Array<ISaleData>,
    item: {} as any,
  }),
  actions: {
    async _index() {
      LoadingStore().start();
      return Fetch().then((response) => {
        this.items = response.data;
        LoadingStore().end();
      });
    },

    async _store(data: any) {
      LoadingStore().start();
      return Store(data)
        .then(() => {
          document.dispatchEvent(new CustomEvent("closeModal"));
        })
        .catch((error) => {
          Swal.fire(swalError(error.response || error));
        })
        .finally(() => LoadingStore().end());
    },

    async _buy(id: number) {
      LoadingStore().start();
      return Buy(id)
        .then((response) => {
          Swal.fire({
            title: "Solicitação Realizada",
            icon: "success",
            html: getMessage(response),
          });
          this._index();
        })
        .catch((error) => {
          Swal.fire(swalError(error));
        })
        .finally(() => LoadingStore().end());
    },

    async _destroy(id: number) {
      LoadingStore().start();
      return Destroy(id)
        .then(() => {
          Swal.fire(swalDeleted());
          destroy(this.items, id);
        })
        .catch((error) => {
          Swal.fire(swalError(error.response || error));
        })
        .finally(() => LoadingStore().end());
    },

    _itemReset() {
      this.item = {} as any;
    },
  },
});
