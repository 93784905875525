import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import * as session from "@/shared/session/Session";

type IHttpRequest = {
  url: string;
  config?: AxiosRequestConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
};

const host = window.location.host;
const subdomain = host.split(".")[0];
const tenantUrl = host.includes("local") ? `http://${subdomain}-api.centralizou.local/` : `https://${subdomain}-api.centralizou.com/`;

export interface IHttpService {
  get(request: IHttpRequest, query?: object): Promise<any>;
  put(request: IHttpRequest, data: any): Promise<any>;
  post(request: IHttpRequest, data: any): Promise<any>;
  delete(request: IHttpRequest): Promise<any>;
  initService(baseUrl: string): void;
}

export interface IAxiosCreator {
  createAxiosInstance(baseUrl: string): AxiosInstance;
}

export class AxiosCreator implements IAxiosCreator {
  createAxiosInstance(baseUrl: string): AxiosInstance {
    return axios.create({
      baseURL: tenantUrl + baseUrl,
      headers: { "Content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
    });
  }
}

export class HttpService implements IHttpService {
  private axiosService: AxiosInstance;
  private axiosCreator: AxiosCreator;

  constructor() {
    this.axiosCreator = new AxiosCreator();
    this.axiosService = axios.create();
  }

  initService(baseUrl: string) {
    this.axiosService = this.axiosCreator.createAxiosInstance(baseUrl);
    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  public async get({ url, config }: IHttpRequest, query: object): Promise<any> {
    try {
      const response = await this.axiosService.get(url, { params: query });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async put({ url, config }: IHttpRequest, data: any): Promise<any> {
    try {
      const response = await this.axiosService.put(url, data, config);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async post({ url, config }: IHttpRequest, data: any): Promise<any> {
    try {
      const response = await this.axiosService.post(url, data, config);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async delete({ url }: IHttpRequest): Promise<any> {
    try {
      const response = await this.axiosService.delete(url);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private _initializeRequestInterceptor() {
    this.axiosService.interceptors.request.use(
      function (config) {
        const token = session.getToken();
        if (token && token !== "undefined") {
          config.headers = Object.assign({}, { Authorization: `Bearer ${token}` }, config.headers);
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }

  private _initializeResponseInterceptor() {
    this.axiosService.interceptors.response.use(
      (response) => {
        return response.data && response.data.error === false ? Promise.resolve(response) : Promise.reject(response);
      },
      function (error) {
        switch (error.response.status) {
          case 401:
            window.location.href = "/";
            break;
          default:
            return Promise.reject(error);
        }
      }
    );
  }
}
