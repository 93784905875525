import { IHttpService, HttpService } from "@/shared/http/Index";
import { ISkillRepository, ISkillResponse, ISkillIndexResponse, ISkillData, ISkillParser } from "../domain/Skill.types";
import { SkillParser } from "./SkillParser";

export class SkillService implements ISkillRepository {
  private httpService: IHttpService;
  private parser: ISkillParser;

  constructor() {
    this.httpService = new HttpService();
    this.parser = new SkillParser();
    this.httpService.initService("rpg/skill");
  }

  async index(): Promise<ISkillIndexResponse> {
    return await this.httpService.get({ url: "/" });
  }

  async show(id: number): Promise<ISkillResponse> {
    return await this.httpService.get({ url: `/${id}` });
  }

  async store(data: ISkillData): Promise<ISkillResponse> {
    return await this.httpService.post({ url: "/" }, this.parser.fromDomain(data));
  }

  async update(id: number, data: ISkillData): Promise<ISkillResponse> {
    return await this.httpService.post({ url: `/${id}` }, this.parser.fromDomain(data));
  }

  async destroy(id: number): Promise<any> {
    return await this.httpService.delete({ url: `/${id}` });
  }
}
