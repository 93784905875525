export const destroy = (state: any, primary: number, meta: any = {}) => {
  state.splice(
    state.findIndex((item) => item.id === primary),
    1
  );

  if (meta && typeof meta.total !== undefined) {
    meta.total -= 1;
    meta.current_total -= 1;
  }
};

export const update = (state: any, payload: any, key = "items") => {
  state[key].splice(
    state[key].findIndex((item) => item.id === payload.id),
    1,
    payload
  );
};

export const order = (state: any, index: any, key = "items", type = "asc") => {
  state[key] =
    type === "asc"
      ? state[key].sort((a, b) => a[index].toString().toLocaleLowerCase() > b[index].toString().toLocaleLowerCase())
      : state[key].sort((a, b) => b[index].toString().toLocaleLowerCase() - a[index].toString().toLocaleLowerCase());
};

export const push = (state: any, item: any, meta: any = {}) => {
  state.push(item);

  if (meta && typeof meta.total !== undefined) {
    meta.total += 1;
    meta.current_total += 1;
  }
};
