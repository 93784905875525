import { defineStore } from "pinia";
import { LoadingStore } from "@/shared/store/LoadingStore";
import Swal from "sweetalert2";
import { swalError } from "@/shared/helpers/Swal";
import { ILoggedUserData } from "../domain/Login.types";
import { Login } from "../useCase/Index";
import * as session from "@/shared/session/Session";
import { router } from "@/routes/index";

export const LoginStore = defineStore("login", {
  state: () => ({
    logged_user: session.getUser(),
  }),
  actions: {
    async _login(params: object) {
      LoadingStore().start();
      Login(params)
        .then((response) => {
          this.logged_user = response.user;
          session.setToken(response.access_token);
          session.setUser(response.user);
          if(response.user.id === 1) {
            router.push("/note");
          } else {
            router.push("/character");
          }
        })
        .catch((error) => {
          Swal.fire(swalError(error.response || error));
        })
        .finally(() => LoadingStore().end());
    },

    _logout() {
      session.clearStorage();
      this.logged_user = {} as ILoggedUserData;
      router.push("/");
    },
  },
});
