import { defineStore } from "pinia";

export const LoadingStore = defineStore("loading", {
  state: () => ({
    active: false as boolean,
  }),
  actions: {
    start() {
      this.active = true;
    },
    end() {
      this.active = false;
    },
  },
});
