import { INoteData, INoteParser } from "../domain/Note.types";
import { jsonToFormData } from "@/shared/helpers/General";
export class NoteParser implements INoteParser {
  apply(response: any): Promise<any> {
    if (response.data instanceof Array) {
      response.data.map((item: INoteData) => this.toDomain(item));
    } else if (response.data instanceof Object) {
      response.data = this.toDomain(response.data);
    }
    return response;
  }

  toDomain(data: INoteData): INoteData {
    const parserData: INoteData = {
      id: data.id,
      status: data.status,
      note: data.note,
      categories: data.categories,
      category_ids: data.category_ids,
      attachment: data.attachment,
      observation: data.observation,
      created_at: data.created_at,
    };

    return parserData;
  }

  fromDomain(data: INoteData): FormData {
    const parserData: INoteData = {
      id: data.id,
      status: data.status,
      note: data.note,
      categories: data.categories,
      category_ids: data.category_ids,
      attachment: data.attachment,
      observation: data.observation,
      created_at: data.created_at,
    };
    if (!parserData.category_ids && parserData.categories && parserData.categories.length)  {
      parserData.category_ids = parserData.categories.reduce((a, o) => (a.push(o.id), a), []);
    } else {
      parserData.category_ids = null;
    }
    const formData = jsonToFormData(parserData);
    if (parserData.id) formData.append("_method", "PUT");
    return formData;
  }
}
