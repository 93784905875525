import { IHttpService, HttpService } from "@/shared/http/Index";
import { IItemRepository, IItemIndexResponse } from "../domain/Item.types";

export class ItemService implements IItemRepository {
  private httpService: IHttpService;

  constructor() {
    this.httpService = new HttpService();
    this.httpService.initService("rpg/item");
  }

  async index(params: any): Promise<IItemIndexResponse> {
    return await this.httpService.get({ url: "/" }, params);
  }
}
