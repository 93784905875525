import { IHttpService, HttpService } from "@/shared/http/Index";
import { IMessageRepository, IMessageResponse, IMessageIndexResponse, IMessageData, IMessageParser } from "../domain/Message.types";
import { MessageParser } from "./MessageParser";

export class MessageService implements IMessageRepository {
  private httpService: IHttpService;
  private parser: IMessageParser;

  constructor() {
    this.httpService = new HttpService();
    this.parser = new MessageParser();
    this.httpService.initService("rpg/message");
  }

  async index(id: number): Promise<IMessageIndexResponse> {
    return this.parser.apply(await this.httpService.get({ url: `/character/${id}` }));
  }

  async show(id: number): Promise<IMessageResponse> {
    return await this.httpService.get({ url: `/${id}` });
  }

  async store(data: IMessageData): Promise<IMessageResponse> {
    return await this.httpService.post({ url: "/" }, data);
  }

  async update(id: number, data: IMessageData): Promise<IMessageResponse> {
    return await this.httpService.post({ url: `/${id}` }, data);
  }

  async destroy(id: number): Promise<any> {
    return await this.httpService.delete({ url: `/${id}` });
  }
}
