import { defineStore } from "pinia";
import { LoadingStore } from "@/shared/store/LoadingStore";
import Swal from "sweetalert2";
import { swalDeleted, swalError, getMessage } from "@/shared/helpers/Swal";
import { IHttpService, HttpService } from "@/shared/http/Index";
import {
  Store,
  Destroy,
  AddMonster,
  GetActiveMonster,
  SetSpeed,
  SetCurrentPlayer,
  GetCurrentPlayer,
  ApplyDamageToMonster,
  SendGiftToAll,
  UseItem,
  UseSkill,
} from "../useCase/Index";
import { update } from "@/shared/store/BaseStore";

export const BattleStore = defineStore("Battle", {
  state: () => ({
    monsters: [],
    currentPlayer: null,
  }),
  actions: {
    async _store() {
      return Store()
        .then(() => {
          document.dispatchEvent(new CustomEvent("closeModal"));
        })
        .catch((error) => {
          Swal.fire(swalError(error.response || error));
        });
    },

    async _destroy() {
      LoadingStore().start();
      return Destroy()
        .then(() => {
          Swal.fire(swalDeleted());
          this._getActiveMonster();
        })
        .catch((error) => {
          Swal.fire(swalError(error));
        })
        .finally(() => LoadingStore().end());
    },

    async _addMonster(monster_id: number) {
      LoadingStore().start();
      return AddMonster(monster_id)
        .then(() => {
          this._getActiveMonster();
        })
        .catch((error) => {
          Swal.fire(swalError(error || error.response));
        })
        .finally(() => LoadingStore().end());
    },

    async _getActiveMonster() {
      LoadingStore().start();
      return GetActiveMonster()
        .then((response) => {
          this.monsters = response.data;
        })
        .finally(() => LoadingStore().end());
    },

    async _setSpeed(id: number, speed: number) {
      return SetSpeed(id, speed).then((response) => {
        update(this, response.data, "monsters");
      });
    },

    async _setCurrentPlayer(data: any) {
      return SetCurrentPlayer(data).then(() => {
        this.currentPlayer = data;
      });
    },

    async _getCurrentPlayer() {
      return GetCurrentPlayer().then((response) => {
        this.currentPlayer = response.data;
      });
    },

    async _applyDamageToMonster(id: number, damage: number, character_id: number) {
      LoadingStore().start();
      return ApplyDamageToMonster(id, damage, character_id)
        .then((response) => {
          this.monsters = response.data;
          Swal.fire({
            title: "Solicitação Realizada",
            icon: "success",
            html: getMessage(response),
          });
        })
        .catch((error) => {
          Swal.fire(swalError(error || error.response));
        })
        .finally(() => LoadingStore().end());
    },

    async _sendGiftToAll() {
      LoadingStore().start();
      return SendGiftToAll().finally(() => LoadingStore().end());
    },

    async _useItem(item_id: number) {
      LoadingStore().start();
      return UseItem(item_id)
        .then((response) => {
          this.currentPlayer = response.data;
          Swal.fire({
            title: "Solicitação Realizada",
            icon: "success",
            html: getMessage(response),
          });
        })
        .finally(() => LoadingStore().end());
    },

    async _useSkill(data: any) {
      LoadingStore().start();
      return UseSkill(data)
        .then((response) => {
          Swal.fire({
            title: "Solicitação Realizada",
            icon: "success",
            html: getMessage(response),
          });
        })
        .catch((error) => {
          Swal.fire(swalError(error.response));
        })
        .finally(() => LoadingStore().end());
    },

    _passTurn(character_id: number) {
      LoadingStore().start();
      const httpService: IHttpService = new HttpService();
      httpService.initService("rpg/battle/pass-turn");
      return httpService
        .get({ url: `/${character_id}` })
        .then((response) => {
          Swal.fire({
            title: "Solicitação Realizada",
            icon: "success",
            html: getMessage(response),
          });
        })
        .catch((error) => {
          Swal.fire(swalError(error.response));
        })
        .finally(() => LoadingStore().end());
    },
  },
});
