const modalMixin = {
  methods: {
    handleClick(e: Event): void {
      if (e.target instanceof HTMLElement && e.target.className.includes("custom-modal")) {
        this.closeModal();
      }
    },
  },
  /* eslint-disable */
  created() {
    const self = this;
    document.addEventListener("openModal", function (e) {
      self.openModal();
    });
    document.addEventListener("closeModal", function (e) {
      self.closeModal();
    });
  },
};
export default modalMixin;
