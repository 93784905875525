import { IHttpService, HttpService } from "@/shared/http/Index";
import {
  IWordCategoryRepository,
  IWordCategoryResponse,
  IWordCategoryIndexResponse,
  IWordCategoryData,
} from "../domain/WordCategory.types";

export class WordCategoryService implements IWordCategoryRepository {
  private httpService: IHttpService;

  constructor() {
    this.httpService = new HttpService();
    this.httpService.initService("educational/word-category");
  }

  async index(params: any): Promise<IWordCategoryIndexResponse> {
    return await this.httpService.get({ url: "/" }, params);
  }

  async show(id: number): Promise<IWordCategoryResponse> {
    return await this.httpService.get({ url: `/${id}` });
  }

  async store(data: IWordCategoryData): Promise<IWordCategoryResponse> {
    return await this.httpService.post({ url: "/" }, data);
  }

  async update(id: number, data: IWordCategoryData): Promise<IWordCategoryResponse> {
    return await this.httpService.put({ url: `/${id}` }, data);
  }

  async destroy(id: number): Promise<any> {
    return await this.httpService.delete({ url: `/${id}` });
  }
}
