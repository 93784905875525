import { IDice } from "../../domain/dice.type";
import { playDicesAction } from "../../actions/play-dices.action";

export default {
  namespaced: true,

  state: {
    dices: null as IDice,
  },

  actions: {
    playDices({ commit }) {
      return Promise.resolve(commit("setDices", playDicesAction()));
    },
  },

  getters: {
    getDices: (state: any) => state.dices as IDice,
  },

  mutations: {
    setDices(state: any, dices: IDice) {
      state.dices = dices;
    },
  },
};
