export const getMessage = (response: any): string | number => {
  switch (true) {
    case response.data === null:
      return "Operação realizada com sucesso!";
    case response.data && !response.data.length && response.data.error === true:
      return response.data.message;
    case typeof response.message === "string":
      return response.message;
    default:
      return Object.values(response.data.errors).flat().toString().split(",").join("<br />");
  }
};

export const swalToDelete = (): any => {
  return {
    title: "Você tem certeza?",
    text: "Ao deletar o registro você não poderá recuperá-lo!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#4caf50",
    cancelButtonColor: "#f44335",
    confirmButtonText: "Sim, deletar!",
    cancelButtonText: "Não, cancelar!",
  };
};

export const swalError = (error: any): any => {
  return {
    title: "Erro",
    icon: "warning",
    html: getMessage(error),
  };
};

export const swalDeleted = (): any => {
  return {
    title: "Operação realizada",
    icon: "success",
    text: "Deletado com sucesso",
  };
};
