import { IHttpService, HttpService } from "@/shared/http/Index";
import {
  INoteCategoryRepository,
  INoteCategoryResponse,
  INoteCategoryIndexResponse,
  INoteCategoryData,
} from "../domain/NoteCategory.types";

export class NoteCategoryService implements INoteCategoryRepository {
  private httpService: IHttpService;

  constructor() {
    this.httpService = new HttpService();
    this.httpService.initService("operational/note-category");
  }

  async index(params: any): Promise<INoteCategoryIndexResponse> {
    return await this.httpService.get({ url: "/" }, params);
  }

  async show(id: number): Promise<INoteCategoryResponse> {
    return await this.httpService.get({ url: `/${id}` });
  }

  async store(data: INoteCategoryData): Promise<INoteCategoryResponse> {
    return await this.httpService.post({ url: "/" }, data);
  }

  async update(id: number, data: INoteCategoryData): Promise<INoteCategoryResponse> {
    return await this.httpService.put({ url: `/${id}` }, data);
  }

  async destroy(id: number): Promise<any> {
    return await this.httpService.delete({ url: `/${id}` });
  }
}
