import { IHttpService, HttpService } from "@/shared/http/Index";

export class BattleService {
  private httpService: IHttpService;

  constructor() {
    this.httpService = new HttpService();
    this.httpService.initService("rpg/battle");
  }

  async store(): Promise<any> {
    return await this.httpService.post({ url: "/" }, {});
  }

  async destroy(): Promise<any> {
    return await this.httpService.delete({ url: `/` });
  }

  async addMonster(monster_id: number): Promise<any> {
    return await this.httpService.get({ url: `/monster/${monster_id}` });
  }

  async getActiveMonster(): Promise<any> {
    return await this.httpService.get({ url: `/active-monster` });
  }

  async setSpeed(id: number, speed: number): Promise<any> {
    return await this.httpService.get({ url: `/${id}/speed/${speed}` });
  }

  async setCurrentPlayer(data: any): Promise<any> {
    return await this.httpService.post({ url: `/current-player` }, { current_player: data });
  }

  async getCurrentPlayer(): Promise<any> {
    return await this.httpService.get({ url: `/current-player` });
  }

  async applyDamageToMonster(id: number, damage: number, character_id: number): Promise<any> {
    return await this.httpService.get({ url: `/${id}/damage/${damage}/${character_id}` });
  }

  async sendGiftToAll(): Promise<any> {
    return await this.httpService.get({ url: `/send-gift-to-all` });
  }

  async useItem(item_id: number): Promise<any> {
    return await this.httpService.get({ url: `/use-item/${item_id}` });
  }

  async useSkill(data: any): Promise<any> {
    return await this.httpService.post({ url: `/use-skill` }, data);
  }
}
