import { IHttpService, HttpService } from "@/shared/http/Index";
import { ICharacterRepository, ICharacterResponse, ICharacterIndexResponse, ICharacterData } from "../domain/Character.types";

export class CharacterService implements ICharacterRepository {
  private httpService: IHttpService;

  constructor() {
    this.httpService = new HttpService();
    this.httpService.initService("rpg/character");
  }

  async index(params: any): Promise<ICharacterIndexResponse> {
    return await this.httpService.get({ url: "/" }, params);
  }

  async show(id: number): Promise<ICharacterResponse> {
    return await this.httpService.get({ url: `/${id}` });
  }

  async store(data: ICharacterData): Promise<ICharacterResponse> {
    return await this.httpService.post({ url: "/" }, data);
  }

  async update(id: number, data: ICharacterData): Promise<ICharacterResponse> {
    return await this.httpService.put({ url: `/${id}` }, data);
  }

  async destroy(id: number): Promise<any> {
    return await this.httpService.delete({ url: `/${id}` });
  }

  async getCurrentCharacter(): Promise<any> {
    return await this.httpService.get({ url: `/current` });
  }

  async applyDamage(id: number, damage: number): Promise<any> {
    return await this.httpService.get({ url: `/${id}/damage/${damage}` });
  }

  async sendGold(data: any): Promise<any> {
    return await this.httpService.post({ url: `/send-gold` }, data);
  }

  async getMySkill(): Promise<any> {
    return await this.httpService.get({ url: `/my-skills` });
  }

  async getAllToBattle(): Promise<any> {
    return await this.httpService.get({ url: `/to-battle` });
  }

  async setSpeed(id: number, speed: number): Promise<any> {
    return await this.httpService.get({ url: `/${id}/speed/${speed}` });
  }
}
