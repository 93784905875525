import { IDice } from "../domain/dice.type";

export const playDicesAction = (): IDice => {
  const dice = {
    first: getDiceNumber(),
    second: getDiceNumber(),
    third: getDiceNumber(),
    total: 0,
  };

  dice.total = dice.first + dice.second + dice.third;
  return dice;
};

function getDiceNumber(): number {
  return Math.floor(Math.random() * (6 - 1 + 1)) + 1;
}
