import { IHttpService, HttpService } from "@/shared/http/Index";
import {
  ICharacterItemRepository,
  ICharacterItemResponse,
  ICharacterItemIndexResponse,
  ICharacterItemData,
} from "../domain/CharacterItem.types";

export class CharacterItemService implements ICharacterItemRepository {
  private httpService: IHttpService;

  constructor() {
    this.httpService = new HttpService();
    this.httpService.initService("rpg/character-item");
  }

  async index(params: any): Promise<ICharacterItemIndexResponse> {
    return await this.httpService.get({ url: "/" }, params);
  }

  async store(data: ICharacterItemData): Promise<ICharacterItemResponse> {
    return await this.httpService.post({ url: "/" }, data);
  }

  async destroy(id: number): Promise<any> {
    return await this.httpService.delete({ url: `/${id}` });
  }

  async use(id: number): Promise<any> {
    return await this.httpService.get({ url: `/use/${id}` });
  }
}
