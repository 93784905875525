import { IHttpService, HttpService } from "@/shared/http/Index";
import { ISaleRepository, ISaleIndexResponse, ISaleData } from "../domain/Sale.types";

export class SaleService implements ISaleRepository {
  private httpService: IHttpService;

  constructor() {
    this.httpService = new HttpService();
    this.httpService.initService("rpg/sale");
  }

  async index(): Promise<ISaleIndexResponse> {
    return await this.httpService.get({ url: `/` });
  }

  async buy(id:number): Promise<any> {
    return await this.httpService.get({ url: `/buy/${id}` });
  }

  async store(data: ISaleData): Promise<any> {
    return await this.httpService.post({ url: "/" }, data);
  }

  async destroy(id: number): Promise<any> {
    return await this.httpService.delete({ url: `/${id}` });
  }
}
