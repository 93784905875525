import { IHttpService, HttpService } from "@/shared/http/Index";
import { ILoginRepository, ILoginResponse } from "../domain/Login.types";

export class LoginService implements ILoginRepository {
  private httpService: IHttpService;

  constructor() {
    this.httpService = new HttpService();
    this.httpService.initService("login");
  }

  async login(data: any): Promise<ILoginResponse> {
    return await this.httpService.post({ url: "/" }, data);
  }
}
