import { IMonsterData, IMonsterParser } from "../domain/Monster.types";
import { jsonToFormData } from "@/shared/helpers/General";
export class MonsterParser implements IMonsterParser {
  apply(response: any): Promise<any> {
    if (response.data instanceof Array) {
      response.data.map((item: IMonsterData) => this.toDomain(item));
    } else if (response.data instanceof Object) {
      response.data = this.toDomain(response.data);
    }
    return response;
  }

  toDomain(data: IMonsterData): IMonsterData {
    const parserData: IMonsterData = {
      id: data.id,
      name: data.name,
      strength: data.strength,
      defense: data.defense,
      speed: data.speed,
      health: data.health,
      attachment: data.attachment,
    };
    return parserData;
  }

  fromDomain(data: IMonsterData): FormData {
    const parserData: IMonsterData = {
      id: data.id,
      name: data.name,
      strength: data.strength,
      defense: data.defense,
      speed: data.speed,
      health: data.health,
      attachment: data.attachment,
    };
    const formData = jsonToFormData(parserData);
    if (parserData.id) formData.append("_method", "PUT");
    return formData;
  }
}
