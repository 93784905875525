import { IMessageData, IMessageParser } from "../domain/Message.types";

export class MessageParser implements IMessageParser {
  apply(response: any): Promise<any> {
    return response.data.map((item: IMessageData) => this.toDomain(item));
  }

  toDomain(data: IMessageData): IMessageData {
    const parserData: IMessageData = {
      id: data.id,
      title: data.title,
      message: data.message.slice(0, 15) + "...",
      character_id: data.character_id,
      created_at: data.created_at,
      updated_at: data.updated_at,
    };
    return parserData;
  }
}
