import { IWordData, IWordParser } from "../domain/Word.types";

export class WordParser implements IWordParser {
  apply(response: any): Promise<any> {
    if (response.data instanceof Array) {
      response.data.map((item: IWordData) => this.toDomain(item));
    } else if (response.data instanceof Object) {
      response.data = this.toDomain(response.data);
    }
    return response;
  }

  toDomain(data: IWordData): IWordData {
    const parserData: IWordData = {
      id: data.id,
      status: data.status,
      word: data.word,
      meaning: data.meaning,
      synonym: data.synonym,
      example: data.example,
      categories: data.categories,
      category_ids: data.category_ids,
      observation: data.observation,
      created_at: data.created_at,
      updated_at: data.updated_at,
      performed_quantity: data.performed_quantity,
      hit_percentage: data.hit_percentage
    };

    return parserData;
  }

  fromDomain(data: IWordData): IWordData {
    const parserData: IWordData = {
      id: data.id,
      status: data.status,
      word: data.word,
      meaning: data.meaning,
      synonym: data.synonym,
      example: data.example,
      categories: data.categories,
      category_ids: data.category_ids,
      observation: data.observation,
      created_at: data.created_at,
      updated_at: data.updated_at,
      performed_quantity: data.performed_quantity,
      hit_percentage: data.hit_percentage
    };
    if (!parserData.category_ids) parserData.category_ids = parserData.categories.reduce((a, o) => (a.push(o.id), a), []);
    return parserData;
  }
}
