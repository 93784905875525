import { createRouter, createWebHistory } from "vue-router";
import Login from "@/modules/general/login/views/Index.vue";
import Word from "@/modules/educational/word/views/Index.vue";
import WordCategory from "@/modules/educational/wordCategory/views/Index.vue";
import WordGroup from "@/modules/educational/wordGroup/views/Index.vue";
import WordGroupTraining from "@/modules/educational/wordGroup/views/Training/Index.vue";
import Note from "@/modules/operational/note/views/Index.vue";
import NoteCategory from "@/modules/operational/noteCategory/views/Index.vue";
import User from "@/modules/general/user/views/Index.vue";
import Character from "@/modules/rpg/character/views/Index.vue";
import CurrentCharacter from "@/modules/rpg/character/views/Current/Index.vue";
import CharacterClass from "@/modules/rpg/characterClass/views/Index.vue";
import CharacterItem from "@/modules/rpg/characterItem/views/Index.vue";
import CharacterByDamage from "@/modules/rpg/character/views/ByDamage/Index.vue";
import Monster from "@/modules/rpg/monster/views/Index.vue";
import Message from "@/modules/rpg/message/views/Index.vue";
import Sale from "@/modules/rpg/sale/views/Index.vue";
import Skill from "@/modules/rpg/skill/views/Index.vue";
import MySkill from "@/modules/rpg/character/views/Current/MySkill.vue";
import Battle from "@/modules/rpg/battle/views/Index.vue";
import Guard from "@/shared/http/Middleware";

const routes = [
  {
    path: "/",
    component: Login,
    beforeEnter: Guard.hasAuth,
    meta: {
      title: "Login",
    },
  },
  {
    name: "word",
    path: "/word",
    meta: {
      title: "Palavras",
    },
    beforeEnter: Guard.auth,
    component: Word,
  },
  {
    name: "word-category",
    path: "/word-category",
    meta: {
      title: "Palavras - Categorias",
    },
    beforeEnter: Guard.auth,
    component: WordCategory,
  },
  {
    name: "word-group",
    path: "/word-group",
    meta: {
      title: "Palavras - Grupos",
    },
    beforeEnter: Guard.auth,
    component: WordGroup,
  },
  {
    name: "word-group.training",
    path: "/word-group/training/:id",
    meta: {
      title: "Treinar - Palavras",
    },
    beforeEnter: Guard.auth,
    component: WordGroupTraining,
  },
  {
    name: "note",
    path: "/note",
    meta: {
      title: "Anotações",
    },
    beforeEnter: Guard.auth,
    component: Note,
  },
  {
    name: "note-category",
    path: "/note-category",
    meta: {
      title: "Anotações - Categorias",
    },
    beforeEnter: Guard.auth,
    component: NoteCategory,
  },
  {
    name: "user",
    path: "/users",
    meta: {
      title: "Usuários",
    },
    beforeEnter: Guard.auth,
    component: User,
  },
  {
    name: "character",
    path: "/character",
    meta: {
      title: "Personagens",
    },
    beforeEnter: Guard.auth,
    component: Character,
  },
  {
    name: "current-character",
    path: "/current-character",
    meta: {
      title: "Seu Personagem",
    },
    beforeEnter: Guard.auth,
    component: CurrentCharacter,
  },
  {
    name: "character-class",
    path: "/character-class",
    meta: {
      title: "Personagens - Classes",
    },
    beforeEnter: Guard.auth,
    component: CharacterClass,
  },
  {
    name: "character-by-damage",
    path: "/character-by-damage",
    meta: {
      title: "Personagens - Dano",
    },
    beforeEnter: Guard.auth,
    component: CharacterByDamage,
  },
  {
    name: "character-item",
    path: "/character-item",
    meta: {
      title: "Inventário",
    },
    beforeEnter: Guard.auth,
    component: CharacterItem,
  },
  {
    name: "monster",
    path: "/monster",
    meta: {
      title: "Monstros",
    },
    beforeEnter: Guard.auth,
    component: Monster,
  },
  {
    name: "message",
    path: "/message/:id",
    meta: {
      title: "Mensagens",
    },
    beforeEnter: Guard.auth,
    component: Message,
  },
  {
    name: "sale",
    path: "/sale",
    meta: {
      title: "Mercado",
    },
    beforeEnter: Guard.auth,
    component: Sale,
  },
  {
    name: "skill",
    path: "/skill",
    meta: {
      title: "Skills",
    },
    beforeEnter: Guard.auth,
    component: Skill,
  },
  {
    name: "my-skill",
    path: "/my-skill",
    meta: {
      title: "Minhas Skills",
    },
    beforeEnter: Guard.auth,
    component: MySkill,
  },
  {
    name: "battle",
    path: "/battle",
    meta: {
      title: "Batalha",
    },
    beforeEnter: Guard.auth,
    component: Battle,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  if (nearestWithTitle) {
    document.title = <string>"Centralizou | " + nearestWithTitle.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  // Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode.removeChild(el));

  next();
});
