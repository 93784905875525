import { ICharacterAttribute } from "../domain/character.type";

export default {
  namespaced: true,

  state: {
    attributes: [
      {
        name: "strength",
        translated: "Força",
      },
      {
        name: "magic",
        translated: "Magia",
      },
      {
        name: "intelligence",
        translated: "Inteligência",
      },
      {
        name: "charisma",
        translated: "Carisma",
      },
      {
        name: "defense",
        translated: "Defesa",
      },
      {
        name: "max_speed",
        translated: "Velocidade",
      },
      {
        name: "max_health",
        translated: "Vida",
      },
    ] as Array<ICharacterAttribute>,
  },

  actions: {},

  getters: {
    getAttributes: (state: any) => state.attributes as Array<ICharacterAttribute>,
  },

  mutations: {},
};
