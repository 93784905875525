import { IHttpService, HttpService } from "@/shared/http/Index";
import { INoteRepository, INoteResponse, INoteIndexResponse, INoteData, INoteParser } from "../domain/Note.types";
import { NoteParser } from "./NoteParser";

export class NoteService implements INoteRepository {
  private httpService: IHttpService;
  private parser: INoteParser;

  constructor() {
    this.httpService = new HttpService();
    this.parser = new NoteParser();
    this.httpService.initService("operational/note");
  }

  async index(params: any): Promise<INoteIndexResponse> {
    return await this.httpService.get({ url: "/" }, params);
  }

  async show(id: number): Promise<INoteResponse> {
    return await this.httpService.get({ url: `/${id}` });
  }

  async store(data: INoteData): Promise<INoteResponse> {
    return await this.httpService.post({ url: "/" }, this.parser.fromDomain(data));
  }

  async update(id: number, data: INoteData): Promise<INoteResponse> {
    return await this.httpService.post({ url: `/${id}` }, this.parser.fromDomain(data));
  }

  async destroy(id: number): Promise<any> {
    return await this.httpService.delete({ url: `/${id}` });
  }
}
