import { IHttpService, HttpService } from "@/shared/http/Index";
import { IMonsterRepository, IMonsterResponse, IMonsterIndexResponse, IMonsterData, IMonsterParser } from "../domain/Monster.types";
import { MonsterParser } from "./MonsterParser";

export class MonsterService implements IMonsterRepository {
  private httpService: IHttpService;
  private parser: IMonsterParser;

  constructor() {
    this.httpService = new HttpService();
    this.parser = new MonsterParser();
    this.httpService.initService("rpg/monster");
  }

  async index(params: any): Promise<IMonsterIndexResponse> {
    return await this.httpService.get({ url: "/" }, params);
  }

  async show(id: number): Promise<IMonsterResponse> {
    return await this.httpService.get({ url: `/${id}` });
  }

  async store(data: IMonsterData): Promise<IMonsterResponse> {
    return await this.httpService.post({ url: "/" }, this.parser.fromDomain(data));
  }

  async update(id: number, data: IMonsterData): Promise<IMonsterResponse> {
    return await this.httpService.post({ url: `/${id}` }, this.parser.fromDomain(data));
  }

  async destroy(id: number): Promise<any> {
    return await this.httpService.delete({ url: `/${id}` });
  }
}
