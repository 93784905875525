import { defineStore } from "pinia";
import { update, push, destroy } from "@/shared/store/BaseStore";
import { IMeta } from "@/shared/http/Index";
import { LoadingStore } from "@/shared/store/LoadingStore";
import Swal from "sweetalert2";
import { swalDeleted, swalError } from "@/shared/helpers/Swal";
import { IUserData } from "../domain/User.types";
import { Fetch, Show, Update, Store, Destroy } from "../useCase/Index";

export const UserStore = defineStore("User", {
  state: () => ({
    items: [] as Array<IUserData>,
    meta: {} as IMeta,
    item: {} as IUserData,
  }),
  actions: {
    async _index(params: object) {
      LoadingStore().start();
      return Fetch(params).then((response) => {
        this.items = response.data;
        this.meta = response.meta;
        LoadingStore().end();
      });
    },

    async _show(id: number) {
      LoadingStore().start();
      return Show(id).then((response) => {
        this.item = response.data;
        LoadingStore().end();
      });
    },

    async _store(data: IUserData) {
      LoadingStore().start();
      return Store(data)
        .then((response) => {
          document.dispatchEvent(new CustomEvent("closeModal"));
          push(this.items, response.data, this.meta);
        })
        .catch((error) => {
          Swal.fire(swalError(error.response || error));
        })
        .finally(() => LoadingStore().end());
    },

    async _update(data: IUserData) {
      LoadingStore().start();
      return Update(data.id, data)
        .then((response) => {
          document.dispatchEvent(new CustomEvent("closeModal"));
          update(this, response.data);
        })
        .catch((error) => {
          Swal.fire(swalError(error.response || error));
        })
        .finally(() => LoadingStore().end());
    },

    async _destroy(id: number) {
      LoadingStore().start();
      return Destroy(id)
        .then(() => {
          Swal.fire(swalDeleted());
          destroy(this.items, id, this.meta);
        })
        .catch((error) => {
          Swal.fire(swalError(error));
        })
        .finally(() => LoadingStore().end());
    },

    _itemReset() {
      this.item = {} as IUserData;
    },
  },
});
