import { defineStore } from "pinia";
import { LoadingStore } from "@/shared/store/LoadingStore";
import { IItemData } from "../domain/Item.types";
import { Fetch } from "../useCase/Index";

export const ItemStore = defineStore("Item", {
  state: () => ({
    items: [] as Array<IItemData>,
  }),
  actions: {
    async _index(params: object) {
      LoadingStore().start();
      return Fetch(params).then((response) => {
        this.items = response.data;
        LoadingStore().end();
      });
    },
  },
});
