import { defineStore } from "pinia";
import { update, push, destroy } from "@/shared/store/BaseStore";
import { IMeta } from "@/shared/http/Index";
import { LoadingStore } from "@/shared/store/LoadingStore";
import Swal from "sweetalert2";
import { swalDeleted, swalError } from "@/shared/helpers/Swal";
import { IWordGroupData, IGetWordsByWordGroupData } from "../domain/WordGroup.types";
import { Fetch, Show, Update, Store, Destroy, SyncWords, GetSyncWords, GetWordsByWordGroup, StoreTrainedWord } from "../useCase/Index";
import {router} from "@/routes";

export const WordGroupStore = defineStore("wordGroup", {
  state: () => ({
    items: [] as Array<IWordGroupData>,
    meta: {} as IMeta,
    item: {} as IWordGroupData,
    sync: {} as object,
    words: [] as Array<IGetWordsByWordGroupData>,
    currentWord: {} as object,
    currentWordIndex: 0 as number,
  }),
  actions: {
    async _index(params: object) {
      LoadingStore().start();
      return Fetch(params).then((response) => {
        this.items = response.data;
        this.meta = response.meta;
        LoadingStore().end();
      });
    },

    async _show(id: number) {
      LoadingStore().start();
      return Show(id).then((response) => {
        this.item = response.data;
        LoadingStore().end();
      });
    },

    async _store(data: IWordGroupData) {
      LoadingStore().start();
      return Store(data)
        .then((response) => {
          document.dispatchEvent(new CustomEvent("closeModal"));
          push(this.items, response.data, this.meta);
        })
        .catch((error) => {
          Swal.fire(swalError(error.response || error));
        })
        .finally(() => LoadingStore().end());
    },

    async _update(data: IWordGroupData) {
      LoadingStore().start();
      return Update(data.id, data)
        .then((response) => {
          document.dispatchEvent(new CustomEvent("closeModal"));
          update(this, response.data);
        })
        .catch((error) => {
          Swal.fire(swalError(error.response || error));
        })
        .finally(() => LoadingStore().end());
    },

    async _destroy(id: number) {
      LoadingStore().start();
      return Destroy(id)
        .then(() => {
          Swal.fire(swalDeleted());
          destroy(this.items, id, this.meta);
        })
        .catch((error) => {
          Swal.fire(swalError(error));
        })
        .finally(() => LoadingStore().end());
    },

    async _syncWords(data: object) {
      LoadingStore().start();
      return SyncWords(data)
        .then(() => {
          document.dispatchEvent(new CustomEvent("closeModal"));
        })
        .catch((error) => {
          Swal.fire(swalError(error));
        })
        .finally(() => LoadingStore().end());
    },

    async _getSyncWords(id: number) {
      LoadingStore().start();
      return GetSyncWords(id)
        .then((response) => {
          this.sync = response.data;
        })
        .finally(() => LoadingStore().end());
    },

    async _getWordsByWordGroup(id: number) {
      LoadingStore().start();
      return GetWordsByWordGroup(id)
        .then((response) => {
          this.words = response.data;
        })
        .finally(() => LoadingStore().end());
    },

    _getCurrentWord() {
      this.currentWord = this.words[this.currentWordIndex];
    },

    _setCurrentWordIndex(data : number) {
      this.currentWordIndex = data;
    },

    async _storeTrainedWord(data: object) {
      LoadingStore().start();
      return StoreTrainedWord(data)
        .then(() => {
          if (this.currentWordIndex + 1 !== this.words.length) {
            this.currentWordIndex += 1;
            this._getCurrentWord();
          }
          else {
            router.push({ name: "word-group" });
          }
        })
        .finally(() => LoadingStore().end());
    },

    _itemReset() {
      this.item = {} as IWordGroupData;
    },
  },
});
