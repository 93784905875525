export const debounce = (fn, delay) => {
  let timeout;

  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const jsonToFormData = (data: any) => {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    if (key !== "attachment") {
      formData.append(`${key}`, data[key] !== null && typeof data[key] !== 'undefined' ? data[key] : "");
    } else if (data[key] && data[key].length) {
      for (let i = 0; i < data[key].length; i++) {
        if (data[key][i].id) {
          formData.append(`attachment_to_remove[${i}]`, data[key][i].id);
        }
        formData.append(`attachment[${i}]`, data[key][i]);
      }
    }
  }
  return formData;
};
